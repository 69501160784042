import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import {createMillionsLabel} from '../../utils/utils';
import {getPortalUrls} from '../../utils/api';
import {ButtonLink} from '../responsiveElements/styledElements';

const Plan = styled.td`
  color: ${theme.textBlack};
  font-family: Gilroy;
  font-size: 22px;
  line-height: 27px;
  font-weight: 800;
  vertical-align: middle;
`;

export const Price = styled.td`
  vertical-align: middle;
  color: ${theme.primaryLight} !important;
  font-family: Gilroy;
  font-size: 50px;
  line-height: 27px;
  font-weight: 800;
  ::before {
    content: '€';
    font-size: 25px;
    font-family: Lato;
    font-weight: 400;
    text-align: center;
    margin-right: 8px;
    color: ${theme.textBlack};
    vertical-align: super;
  }
`;

export const Points = styled.td`
  vertical-align: middle;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  p {
    margin-bottom: 12px !important;
  }
  p:first-child::after {
    content: '${props => (props.development ? '/ day' : '/ month')}';
    font-size: 16px;
    margin-left: 5px;
    font-weight: normal;
    line-height: 19px;
  }
  p:last-child {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    opacity: 0.6;
  }

  p:last-child::after {
    content: '${props => (props.hasPointPrice ? '€ per 1k point' : '')}';
  }
`;

export const Keys = styled.td`
  vertical-align: middle;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
`;

export const Support = styled.td`
  vertical-align: middle;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;

  div {
    display: inline;
  }
`;

const Button = styled(ButtonLink)`
  margin-left: 56px;
`;

/**
 "id": 2,
 "strategy": "FIXED_MONTH",
 "name": "Small",
 "description": null,
 "price": 49,
 "pointLimit": 10000,
 "pointPrice": null,
 "keys": null,
 "support": false,
 "development": false
 */
export const generateRows = ({pricingRows}) => {
  const rows = [...pricingRows].sort((a, b) => a.price - b.price);

  const [config, setConfig] = React.useState(null);
  React.useEffect(() => {
    if (!config) {
      getPortalUrls().then(response => setConfig(response));
    }
  });

  return (
    <>
      {rows.map(row => (
        <tr key={row.name}>
          <Plan>{row.name}</Plan>
          <Price>{row.price}</Price>
          <Points development={row.development} hasPointPrice={!!row.pointPrice}>
            <div>
              <p>{createMillionsLabel(row.pointLimit, ' ')}</p>
              <p>{row.pointPrice ? (row.pointPrice * 1000).toFixed(2) : ''}</p>
            </div>
          </Points>
          <Keys>{row.keys ? row.keys : 'unlimited'}</Keys>
          <Support>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {row.support ? 'yes' : 'no'}
              <Button href={!!config ? config.PORTAL_URL : '/404'}>
                {row.price ? 'Start free trial' : 'Get started'}
              </Button>
            </div>
          </Support>
        </tr>
      ))}
    </>
  );
};
