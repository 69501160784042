import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import PricingTable from './pricingTable';
import Spinner from '../spinner';
import {ErrorBox} from '../errorBox';
import {Content, Section as rawSection} from '../responsiveElements/styledElements';

const Section = styled(rawSection)`
  background: linear-gradient(${theme.textWhite} 440px, ${theme.primaryBlue} 440px);
`;

const fetchPricesErrorMsg = 'Unable to fetch request prices. Try refreshing the site';

const TableSection = ({loading, error, pricingValues}) => {
  return (
    <Section>
      <Content>
        {loading ? (
          <Spinner />
        ) : error ? (
          <ErrorBox content={fetchPricesErrorMsg} />
        ) : (
          <PricingTable rowValues={pricingValues} />
        )}
      </Content>
    </Section>
  );
};

export default TableSection;
