import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import {hexToRgb} from '../../utils/utils';
import {ButtonLink} from '../responsiveElements/styledElements';
import {getPortalUrls} from '../../utils/api';
import {useSmallerCards} from '../responsiveElements/useWindowWidth';
import {CardRows} from './cardRows';

const Card = styled.div`
  background: ${theme.textWhite};
  width: ${props => (props.small ? '280px' : '334px')};
  height: 530px;
  border-radius: 6px;
  box-shadow: 0 42px 60px -20px rgba(38, 38, 44, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const HeadingDiv = styled.div`
  width: 100%;
  border-radius: 6px 6px 0 0;
  padding: 26px 32px;

  height: 78px;
  background: ${theme.primaryLight};
  color: ${theme.textWhite};
  h2 {
    font-size: 22px;
  }
`;

const InfoTable = styled.table`
  width: 100%;
  color: ${theme.textBlack};

  tr:nth-child(even) {
    background-color: ${hexToRgb(theme.secondaryBlue, 0.1)};
  }
  td {
    height: 80px;
    border: 1px solid rgb(29, 73, 70, 0.1);
    border-top: none;
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    vertical-align: center;
  }
  td:first-child {
    border-left: none;
    padding-left: 32px;
  }

  td:last-child {
    border-right: none;
  }
`;

const Button = styled(ButtonLink)`
  position: absolute;
  bottom: 32px;
`;

export const PlanCard = ({plan}) => {
  const {name, price} = plan;
  const small = useSmallerCards();

  const [config, setConfig] = React.useState(null);
  React.useEffect(() => {
    if (!config) {
      getPortalUrls().then(response => setConfig(response));
    }
  });

  return (
    <Card small={small}>
      <HeadingDiv>
        <h2>{name}</h2>
      </HeadingDiv>
      <InfoTable>
        <CardRows plan={plan} />
      </InfoTable>
      <Button href={!!config ? config.PORTAL_URL : '/404'}>
        {price ? 'Start free trial' : 'Get started'}
      </Button>
    </Card>
  );
};
