import {useEffect, useState} from 'react';

export const useWindowWidth = () => {
  let innerWidth = 0;
  if (typeof window !== `undefined`) {
    innerWidth = window.innerWidth;
  }

  const [width, setWidth] = useState(innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
};

export const useSmallerCards = () => {
  const width = useWindowWidth();
  return width > 1000 || (width < 760 && width > 650) || width < 340;
};
