import React, {Component} from 'react';
import api from '../../utils/api';
import TableSection from './pricingContent';
import {Descriptive} from './descriptiveSection';

class Wrapper extends Component {
  state = {
    loading: true,
    error: false,
    pricingValues: [],
  };

  componentDidMount() {
    api
      .get('/api/1/pricing_plan')
      .then(response => this.setState({loading: false, error: false, pricingValues: response}))
      .catch(error => this.setState({loading: false, error: true}));
  }

  render() {
    const {loading, error, pricingValues} = this.state;
    const {descriptions} = this.props;
    return (
      <>
        <TableSection loading={loading} error={error} pricingValues={pricingValues} />
        <Descriptive tableOnTop={!loading && !error} content={descriptions} />
      </>
    );
  }
}

export default Wrapper;
