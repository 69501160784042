import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../utils/theme';
import {Content, Section as rawSection} from '../responsiveElements/styledElements';

const Section = styled(rawSection)`
  background: ${theme.textWhite};
  padding-top: 75px !important;
  padding-bottom: 85px !important;

  td {
    padding: 12px 0 16px 12px;
  }
  h1 {
    margin-bottom: 12px;
  }

  h2 {
    margin-bottom: 20px;
  }

  p {
    max-width: 722px;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 44px;
  }

  table {
    max-width: 1600px;
    width: 100%;
  }
  th {
    min-width: 0 !important;
  }
`;

const PointSystem = ({content, options = {}}) => {
  return (
    <Section bgColor={options.bgColor}>
      <Content dangerouslySetInnerHTML={{__html: content}} />
    </Section>
  );
};

PointSystem.propTypes = {
  content: PropTypes.string.isRequired,
};

export default PointSystem;
