import React from 'react';
import Layout from '../Components/layout';
import Hero from '../Components/hero';
import theme from '../utils/theme';
import {graphql} from 'gatsby';
import PointSystem from '../Components/pricing/pointSystem';
import {filterByTypeSortByOrder} from '../utils/utils';
import Wrapper from '../Components/pricing/tableAndDescriptionWrapper';

const Pricing = ({
  data: {
    allMarkdownRemark: {edges},
  },
}) => {
  const hero = edges.find(edge => edge.node.frontmatter.type === 'hero');
  const pointSystem = edges.find(edge => edge.node.frontmatter.type === 'pointSystem');
  const descriptions = filterByTypeSortByOrder(edges, 'description');

  return (
    <Layout>
      <Hero isPricingHero options={{bgColor: theme.textWhite}} content={hero.node.html} />
      <Wrapper descriptions={descriptions} />
      <PointSystem content={pointSystem.node.html} />
    </Layout>
  );
};

export default Pricing;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "pricing"}}}) {
      edges {
        node {
          html
          frontmatter {
            type
            id
            order
          }
        }
      }
    }
  }
`;
