import React from 'react';
import styled from 'styled-components';
import error from '../../static/img/error-circle.svg';

const Main = styled.div`
  height: 75px;
  max-width: 472px;
  border: 1px solid #f1663c;
  border-radius: 5px;
  background-color: rgba(241, 102, 60, 0.05);
  padding: 24px 32px 24px 12px;
  margin: 0 auto;

  span {
    font-size: 16px;
  }

  span:before {
    content: url(${error});
    margin-right: 12px;
    vertical-align: middle;
  }
`;

export const ErrorBox = ({content}) => (
  <Main>
    <span>{content}</span>
  </Main>
);
