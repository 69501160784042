import React from 'react';
import {Price, Keys, Support} from './tableRows';
import {createMillionsLabel} from '../../utils/utils';
import styled from 'styled-components';

const LabelTd = styled.td`
  font-size: 19px;
  vertical-align: middle;
`;

const Points = styled.td`
  vertical-align: middle;

  p {
    margin-bottom: 12px;
  }

  span {
    font-size: 16px;
    font-weight: normal;
  }
`;

const LargeText = styled.p`
  font-size: 20px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 12px !important;
`;

const OpaqueText = styled.p`
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    opacity: 0.6;   
    &:after {
    content: '${props => (props.hasPointPrice ? '€ per 1k point' : '')}';
  }
`;

const SmallText = styled.span`
   display: inline-block;
   font-size: 16px;
   font-weight: normal;
   line-height: 16px;
   font-weight: normal !important; 
  }
`;

export const CardRows = ({plan}) => {
  const {name, price, development, pointLimit, pointPrice, support} = plan;
  return (
    <tbody>
      <tr key={name}>
        <LabelTd>Monthly price</LabelTd>
        <Price>{price}</Price>
      </tr>

      <tr>
        <LabelTd>Points</LabelTd>
        <Points>
          <LargeText>
            {createMillionsLabel(pointLimit, ' ')}
            <SmallText>&nbsp;{development ? '/ day' : '/ month'}</SmallText>
          </LargeText>

          <OpaqueText hasPointPrice={!!pointPrice}>
            {pointPrice ? (pointPrice * 1000).toFixed(2) : ''}
          </OpaqueText>
        </Points>
      </tr>

      <tr>
        <LabelTd>Keys</LabelTd>
        <Keys>{plan.keys ? plan.keys : 'unlimited'}</Keys>
      </tr>

      <tr>
        <LabelTd>Support</LabelTd>
        <Support>
          <div style={{display: 'flex', alignItems: 'center'}}>{support ? 'yes' : 'no'}</div>
        </Support>
      </tr>
    </tbody>
  );
};
