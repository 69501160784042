import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import {Content as plainContent, Section as rawSection} from '../responsiveElements/styledElements';

const Section = styled(rawSection)`
  background: ${theme.primaryBlue};
`;

const Content = styled(plainContent)`
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(
    auto-fit,
    ${props => (props.smallCards && props.width > 320 ? '40%' : '280px')}
  );
  grid-column-gap: ${props => (props.smallCards && props.width > 320 ? '20%' : '10%')};

  h2 {
    margin: 0 0 20px 0;
  }
  p {
    max-width: 650px;
    min-width: 280px;
    margin-bottom: 60px;
  }

  a.gatsby-resp-image-wrapper,
  img,
  a.gatsby-resp-image-link {
    padding: 0;
    background: transparent;
  }

  img {
    margin-bottom: 24px;
  }
  p:first-child {
    max-width: 64px;
    max-height: 52px;
    margin: 0 0 26px 0;
  }
`;

export const Descriptive = ({content, tableOnTop}) => {
  const leftHTMLstring = content[0].node.html;
  const rightHTMLstring = content[1].node.html;
  const [width, setWidth] = useState(0);

  useEffect(() => setWidth(window.innerWidth));

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const smallCards = width > 1000 || (width < 760 && width > 650) || width < 340;

  return (
    <Section style={{paddingTop: !tableOnTop ? '72px' : '0px'}}>
      <Content width={width} smallCards={smallCards}>
        <div dangerouslySetInnerHTML={{__html: leftHTMLstring}} />
        <div dangerouslySetInnerHTML={{__html: rightHTMLstring}} />
      </Content>
    </Section>
  );
};
