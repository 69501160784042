import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';
import {generateRows as Rows} from './tableRows';
import {hexToRgb} from '../../utils/utils';
import {useSmallerCards, useWindowWidth} from '../responsiveElements/useWindowWidth';
import {PlanCard} from './planCard';
import params from '../../utils/params';

const StyledTable = styled('table')`
  width: 100%;
  max-width: 1600px;
  text-align: left;
  box-shadow: 0 42px 60px -20px rgba(38, 38, 44, 0.2);
  border-collapse: collapse;
  border-radius: 0 0 6px 6px;

  th {
    font-size: 16px;
    height: 55px;
    padding: 19px 32px 17px;
    background: ${theme.primaryLight};
    color: ${theme.textWhite};
    border-left: 1px solid rgb(29, 73, 70, 0.1);
  }

  td {
    height: 150px;
    padding-left: 32px;
    @media only screen and (${params.mediaQueryMaxWidth1024}) {
      padding-left: 16px;
    }
    color: ${theme.textBlack};
    border-left: 1px solid rgb(29, 73, 70, 0.1);
  }

  td:first-child {
    border-left: none;
    padding-left: 32px;
  }

  td:last-child {
    border-right: none;
  }

  th:first-child {
    border-radius: 6px 0 0 0;
  }
  th:last-child {
    border-radius: 0 6px 0 0;
  }

  tr:last-child {
    border-radius: 0 0 6px 6px;
  }
  tr:last-child td:last-child {
    border-radius: 0 0 6px 0;
  }

  tr:last-child td:first-child {
    border-radius: 0 0 0 6px;
  }

  tr: nth-child(even) {
    background-color: ${theme.textWhite};
  }

  tr: nth-child(odd) {
    background-color: ${hexToRgb(theme.secondaryBlue, 0.1)};
  }
`;

const GridOfCards = styled.div`
  justify-content: center;
  width: 100%;
  display: grid;
  column-gap: 30px;
  row-gap: 48px;
  grid-template-columns: repeat(auto-fit, ${props => (props.smallCards ? '280px' : '334px')});
`;

const PricingTable = ({rowValues}) => {
  const width = useWindowWidth();
  const smallCards = useSmallerCards();
  if (width > 1023) {
    return (
      <StyledTable>
        <tbody>
          <tr>
            <th>Plan</th>
            <th>Monthly price</th>
            <th>Points </th>
            <th>Keys</th>
            <th>Support</th>
          </tr>
          <Rows pricingRows={rowValues.sort((a, b) => a.pointLimit - b.pointLimit)} />
        </tbody>
      </StyledTable>
    );
  } else {
    return (
      <GridOfCards smallCards={smallCards}>
        {rowValues
          .sort((a, b) => a.pointLimit - b.pointLimit)
          .map(row => (
            <PlanCard key={row.name} plan={row} />
          ))}
      </GridOfCards>
    );
  }
};

export default PricingTable;
